import React from 'react'

import axios from 'axios';
import '../styles/main.css';import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../styles/navbar.css';
import moment, { relativeTimeThreshold, duration } from 'moment'
import swal from '@sweetalert/with-react';
import Spinner from 'react-bootstrap/Spinner'
import GlobalFunctions from '../controllers/globalFunctions';
import "react-sweet-progress/lib/style.css";
import 'rsuite-table/dist/css/rsuite-table.css';
import "react-datepicker/dist/react-datepicker.css";


class Invoices extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        clients: [],
        clientList : [],
        loading: true,
        loadingClients: true,
        selectedClientId: 0,
        selectedClientName: '',
        loadingInvoices: false,
        invoices: [],
        weekStart: '',
        weekEnd: '',
        searchInput :''
      };
      
    }
    handleChangeClientNameFilter= event => {
      this.setState({ [event.target.name]: event.target.value }, () => {
        this.filterClientByName();
      });
    };
  
    filterClientByName = () => {
      let { searchInput } = this.state;
      let filteredData = this.state.clientList.filter(value => {
        return (
          value.clientname.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      this.setState({ clients : filteredData });
    };
  
    componentDidMount() {
      this.calculateWeekRange();
      GlobalFunctions.extractToken();
      this.getClients();
    }

    getClients = () => {
      this.setState({ loadingClients: true })
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ clients: client.data, clientList : client.data, loadingClients: false });
      }).catch(error=>{
        GlobalFunctions.networkFailureAlert(error)
      })
    }
    sendInvoiceToXero = (event) =>{
      var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/insertInvoice?start=${event.weekStart}&end=${event.weekEnd}&clientId=${event.clientId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.post(url1).then(client => {
        this.getUnsyncronizedClientInvoices({ clientId: this.state.selectedClientId, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
        swal("successfully sent!", " ", "success");
      }).catch(error => {
        if(error.response){
          swal("Error Message",error.response.data.error, "warning")
        }else{
          GlobalFunctions.networkFailureAlert(error)
        }
        
      })
  
    }
    getUnsyncronizedClientInvoices = (event) =>{
      this.setState({ loadingInvoices: true })
      var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/invoicesToBeSendToXero?start=${event.weekStart}&end=${event.weekEnd}&clientId=${event.clientId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        var data = client.data;
        function sort_by_key(array, key) {
          return array.sort(function(a, b)
          {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          });
          }
        data = sort_by_key(data, 'Date');
        this.setState({ loadingInvoices: false, invoices: client.data });
      }).catch(error => {
        GlobalFunctions.networkFailureAlert(error);
      })
    }
  
    invoiceAll = (event) =>{
      var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/insertInvoice?start=${this.state.weekStart}&end=${this.state.weekEnd}&all=${true}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.post(url1).then(client => {
        swal("successfully sent!", " ", "success")
      }).catch(error => {
        GlobalFunctions.networkFailureAlert(error)
      })
    }
  
    calculateWeekRange = () =>{
      let now = moment();
      const dayName = moment(now).format('dddd');
      var day = Number(now.weekday())
      var start = now.subtract(day - 1, 'days').format('MM-DD-YYYY');
      var end = now.add(6, 'days').format('MM-DD-YYYY');
      if(dayName=='Sunday'){
        end = moment(start).format('MM-DD-YYYY');
        end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
        start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
      }
      this.setState({ weekStart: start, weekEnd: end })
    }
  
    getNextWeek = () =>{
      var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
      start = moment(start).add(1, 'days').format('MM-DD-YYYY');
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
      //Getting Data from the API after navigating to nextweek
      this.getUnsyncronizedClientInvoices({ clientId: this.state.selectedClientId, weekStart: start, weekEnd: end });
      this.setState({ weekStart: start, weekEnd: end })
    }
  
    getPreviousWeek = () =>{
      var end = moment(this.state.weekStart).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');
      //Getting Data from the API after navigating to previousweek
      this.getUnsyncronizedClientInvoices({ clientId: this.state.selectedClientId, weekStart: start, weekEnd: end });
      this.setState({ weekStart: start, weekEnd: end })
    }
    render() {
      const columns = [{
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Date
          </button>
        ),
        minWidth: 25,
        Cell: row => {
          var date = row.original.Date;
          date = moment(date).format('llll');
          date = date.split(" ");
          return (
            <div>
              <small>
                {
                  date[0] + " " + date[1] + " " + date[2] + " " + date[3]
                }
              </small>
              
            </div>
          )
        }
  
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Start
          </button>
        ),
        minWidth: 15,
        Cell : row =>{
          return (
            <small>
              {row.original.startTime}<br></br>
              <div className="text-primary">
                {row.original.service_for_non_billable_hours?row.original.endTime:``}
              </div>
            </small>
          )
        }
  
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            End
          </button>
        ),
        minWidth: 15,
        Cell : row =>{
          return (
            <small>
              {row.original.endTime}<br></br>
              <div className="text-primary">
                {row.original.service_for_non_billable_hours?moment(row.original.endTime, "HH:mm A").add(1,'hours').format("HH:mm"):``}
              </div>
            </small>
          )
        }
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Service
          </button>
        ),
        Cell: row => {
          return (
            <div>
              <small>{row.original.tbl_support_service.support_service}</small> 
              <br></br>
              {
                row.original.service_for_non_billable_hours? 
                <small className="text-primary">
                  {row.original.service_for_non_billable_hours.support_service} - Non to face billable hours
                </small>
                :
                ''
              }
            </div>
          )
        }
      }, {
  
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Price
          </button>
        ),
        accessor: 'costPerHour',
        maxWidth : 85,
        Cell: row => {
          return (
            <div>
              <small>{row.original.costPerHour} </small>
              <br></br>
              {
                  row.original.service_for_non_billable_hours? 
                  <small className="text-primary">
                    {row.original.addExtraHourForInvoicing_price}
                  </small>
                  :
                  ''
                }
            </div>
          )
        }
      }, {
        Header: () => (
          <button className="btn text-white  btn-warning btn-block" type="button">
            Status
          </button>
        ),
        maxWidth: 120,
        Cell: row => {
          return (
            <div>
              {row.original.isXeroSync ?
                <span class="badge badge-success">Syncronized</span> :
                <span class="badge badge-danger">Unsyncronized</span>
              }
            </div>
          )
        }
      }]
  
  
      return (
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
            <h1 className="h2">{this.state.selectedClientName}</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2">
                <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
                <button type="button" className="btn btn-sm btn-outline-secondary">
                  <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
                <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
              </div>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-2"
                onClick={() => {
                  this.invoiceAll();
                }}
              >
                Bulk invoice
            </button>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4" >
            <div>
                <form>
                  <input
                    name="searchInput"
                    defaultValue={this.state.searchInput}
                    onChange={(event)=>{
                      this.setState({lastFilterApplied : 'Staff'});
                      this.handleChangeClientNameFilter(event);
                    }}
                    id="input1"
                    className="form-control ml-2 col-md-12"
                    type="text"
                    placeholder="Search"
                    autoFocus
                    />
                </form>
              </div>
              <div style={{ height: "450px", overflowY: "scroll" }}>
                {
                  this.state.loadingClients ?
                    <Spinner animation="border" role="status" size="lg" className="text-center" style={{ width: "4rem", height: "4rem", marginLeft: "10rem", marginTop: "8rem" }}>
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    this.state.clients.map((client) =>
                      <div className="">
                        {
                          GlobalFunctions.extractToken().isXeroConnected==true?
                          <div className="ml-2 row"
                            onClick={() => {
                                  var index = this.state.clients.findIndex(x => x.id == client.id);
                                  this.setState({ selectedClientId: client.id, selectedClientName: this.state.clients[index].clientname });
                                  this.getUnsyncronizedClientInvoices({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                                }}
                          >
                            <div
                            className="btn text-white btn-warning btn-block mt-2 ml-1 text-left col-md-8 " type="button"
                            style={{ 'backgroundColor': client.id == this.state.selectedClientId ? '#AFAD12' : '#f7c133' }}
                                type="button"
                                key={client.id}
                                onClick={() => {
                                  var index = this.state.clients.findIndex(x => x.id == client.id);
                                  this.setState({ selectedClientId: client.id, selectedClientName: this.state.clients[index].clientname });
                                  this.getUnsyncronizedClientInvoices({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                                }}
                            >
                              <a href="#"
                              className="text-white text-decoration-none"
                              >
                                <small>{`${client.clientname}`}</small>
                              </a>
                            </div>
                            <button
                                type="button"
                                className="btn btn-sm btn-info float-right mt-2 btn-block ml-1 col-md-3"
                                onClick={() => {
                                  this.sendInvoiceToXero({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd })
                                }}
                              >
                                Send to xero
                            </button>
                          </div>
                          :
                          <div
                            className="btn text-white btn-warning btn-block mt-2 ml-1 text-left col-md-12 " type="button"
                            style={{ 'backgroundColor': client.id == this.state.selectedClientId ? '#AFAD12' : '#f7c133' }}
                                type="button"
                                key={client.id}
                                onClick={() => {
                                  var index = this.state.clients.findIndex(x => x.id == client.id);
                                  this.setState({ selectedClientId: client.id, selectedClientName: this.state.clients[index].clientname });
                                  this.getUnsyncronizedClientInvoices({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                                }}
                          >
                            <a href="#"
                            className="text-white text-decoration-none"
                            >
                              <small>{`${client.clientname}`}</small>
                            </a>
                          </div>
                        }
                      </div>
                    )
                }
              </div>
            </div>
            <div className="col-md-8">
              <ReactTable
                data={this.state.invoices}
                loading={this.state.loadingInvoices}
                columns={columns}
                defaultPageSize={10}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  export default Invoices;
  